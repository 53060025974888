<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#30B868" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start pt-4 px-2 px-lg-0 pl-lg-4 pl-xl-4>
      <v-flex xs12>
        <v-layout pt-3 wrap justify-start>
          <v-flex xs7 sm3 md3 lg2 xl2>
            <v-layout wrap justify-center>
              <v-flex xs3 sm2 text-left align-self-center>
                <v-img
                  src="./../../../assets/greenlogo.png"
                  contain
                  height="15px"
                  alt="STYLOOP"
                ></v-img>
              </v-flex>
              <v-flex xs9 sm10 text-left pl-2 align-self-center>
                <span
                  style="
                    font-family: poppinssemibold;
                    font-size: 18px;
                    color: #1c1c1c;
                  "
                >
                  Seller Reviews
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout
      wrap
      justify-center
      py-4
      px-2
      pl-xl-4
      v-for="(item, i) in reviews"
      :key="i"
    >
      <v-flex xs12 sm11 text-left pb-4 pl-2>
        <span
          style="font-family: opensansbold; font-size: 20px; color: #808080"
        >
          {{ formatDate(item.create_date) }}
        </span>
      </v-flex>
      <v-layout wrap justify-center>
        <v-flex xs12 sm11>
          <v-card>
            <v-layout wrap justify-star py-3 px-5>
              <v-flex xs11 sm11 md11 lg11 xl11 py-2 text-start>
                <v-layout wrap fill-height v-if="item.userId">
                  <v-flex xs12 sm12 md12 align-self-center text-left>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span class="orderHeading">Customer Details</span>
                      </v-flex>
                      <v-flex xs12 pt-2>
                        <span
                          style="
                            color: #7e8f85;
                            font-family: poppinsregular;
                            font-size: 14px;
                          "
                          >Name :
                        </span>
                        <span class="orderText">{{ item.userId.name }}</span>
                      </v-flex>
                      <v-flex xs12>
                        <span
                          style="
                            color: #7e8f85;
                            font-family: poppinsregular;
                            font-size: 14px;
                          "
                          >Phone :
                        </span>
                        <span class="orderText">{{ item.userId.phone }}</span>
                      </v-flex>
                      <v-flex xs12>
                        <span
                          style="
                            color: #7e8f85;
                            font-family: poppinsregular;
                            font-size: 14px;
                          "
                          >Email :
                        </span>
                        <span class="orderText">{{ item.userId.email }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs1 sm1>
                <v-switch
                  v-model="item.showFeedback"
                  :true-value="true"
                  :false-value="false"
                  color="#5DC57C"
                  hide-details
                  :ripple="false"
                  class="statusKey"
                  :loading="statusLoading"
                  @click="changeStatus(item)"
                >
                  <!-- <template slot="placeholder">
                        <span
                          style="
                            color: #000000;
                            font-family: opensanssemibold;
                            font-size: 16px;
                          "
                        >
                          {{ item.status }}
                        </span>
                      </template> -->
                </v-switch>
              </v-flex>
              <v-flex xs12 py-2>
                <v-divider></v-divider>
              </v-flex>
              <v-layout wrap>
                <v-flex xs12 text-left>
                  <span class="orderHeading">Rating & Review</span>
                </v-flex>
                <v-flex xs12 sm12 md12 lg12 text-left>
                  <v-rating
                    color="#F28F34"
                    background-color="#F28F34"
                    half-increments
                    readonly
                    small
                    hover
                    v-model="item.rating"
                  ></v-rating>
                </v-flex>
                <v-flex xs12 pt-2 text-left>
                  <span
                    style="
                      color: #3c3c3c;
                      font-family: poppinsregular;
                      font-size: 13px;
                    "
                    >{{ item.review }}</span
                  >
                </v-flex>
              </v-layout>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-layout>
    <v-layout wrap justify-center pt-2>
      <v-flex xs12>
        <div class="text-center pb-5" v-if="pages > 1">
          <v-pagination
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#30B868"
            circle
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-if="reviews.length < 1">
      <v-flex xs12>
        <span
          style="font-family: poppinsregular; font-size: 25px; color: #000000"
        >
          Oops! No Reviews Found
        </span>
      </v-flex>
    </v-layout>
  </div>
</template>
    <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      timeout: 5000,
      msg: "",
      ServerError: false,
      showSnackBar: false,
      reviews: [],
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 16,
      statusLoading: false,
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/feedback/Seller/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          limit: this.limit,
          page: this.currentPage,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.reviews = response.data.data;
            this.pages = Math.ceil(response.data.totalLength / this.limit);
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    changeStatus(item) {
      this.statusLoading = true;
      axios({
        method: "POST",
        url: "/feedback/statusChange",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: item._id,
        },
      })
        .then((response) => {
          this.statusLoading = false;
          if (response.data.status) {
            this.msg = "Review Status Changed";
            this.showSnackBar = true;
            this.getData();
          }
        })
        .catch((err) => {
          this.statusLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var defDate = new Date(item);
      defDate = defDate.toString().slice(0, 15);
      var today = new Date();
      today = today.toString().slice(0, 15);

      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      yesterday = yesterday.toString().slice(0, 15);

      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(0, 4) + day + " " + dt.slice(4, 7) + " " + year;

      if (today == defDate) {
        return "Today";
      } else if (yesterday == defDate) {
        return "Yesterday";
      } else {
        return strTime;
      }
    },
  },
};
</script>